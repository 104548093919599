// Imports
import Vue from 'vue'
import Router from 'vue-router'
import { PL, EN } from '@/constants/lang.js'
import store from '../store/index'
import { SET_LANG } from '../store/mutation-types'
import { GET_PAGE } from '../store/action-types'
import { ALL, STORE_NAMESPACE_ALL } from '@/App.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '',
          name: 'Home-pl',
          component: () => import('@/views/home/Index.vue'),
          meta: { lang: PL, title: 'Strona Główna' },
        },
        {
          path: 'home',
          name: 'Home-en',
          component: () => import('@/views/home/Index.vue'),
          meta: { lang: EN, title: 'Home' },
        },
        {
          path: 'o-firmie',
          name: 'About-pl',
          component: () => import('@/views/about/Index.vue'),
          meta: { src: require('@/assets/about-hero.jpg'), lang: PL, title: 'O firmie' },
        },
        {
          path: 'about',
          name: 'About-en',
          component: () => import('@/views/about/Index.vue'),
          meta: { src: require('@/assets/about-hero.jpg'), lang: EN, title: 'About' },
        },
        // {
        //   path: 'aktualnosci',
        //   name: 'News-pl',
        //   component: () => import('@/views/news/Index.vue'),
        //   meta: { src: require('@/assets/news.jpg'), lang: PL },
        // },
        // {
        //   path: 'news',
        //   name: 'News-en',
        //   component: () => import('@/views/news/Index.vue'),
        //   meta: { src: require('@/assets/about.jpg'), lang: EN },
        // },
        {
          path: 'oferta',
          name: 'Offer-pl',
          component: () => import('@/views/offer/Index.vue'),
          meta: { src: require('@/assets/offer-hero.jpg'), lang: PL, title: 'Oferta' },
        },
        {
          path: 'offer',
          name: 'Offer-en',
          component: () => import('@/views/offer/Index.vue'),
          meta: { src: require('@/assets/offer-hero.jpg'), lang: EN, title: 'Offer' },
        },
        {
          path: 'certyfikaty',
          name: 'Certificates-pl',
          component: () => import('@/views/certificates/Index.vue'),
          meta: { src: require('@/assets/certificates-hero.jpg'), lang: PL, title: 'Certyfikaty' },
        },
        {
          path: 'certificates',
          name: 'Certificates-en',
          component: () => import('@/views/certificates/Index.vue'),
          meta: { src: require('@/assets/certificates-hero.jpg'), lang: EN, title: 'Certificates' },
        },
        {
          path: 'realizacje',
          name: 'Realizations-pl',
          component: () => import('@/views/realizations/Index.vue'),
          meta: { src: require('@/assets/realizations-hero.jpg'), lang: PL, title: 'Realizacje' },
        },
        {
          path: 'realizations',
          name: 'Realizations-en',
          component: () => import('@/views/realizations/Index.vue'),
          meta: { src: require('@/assets/realizations-hero.jpg'), lang: EN, title: 'Realizations' },
        },
        // {
        //   path: 'referencje',
        //   name: 'References-pl',
        //   component: () => import('@/views/references/Index.vue'),
        //   meta: { src: require('@/assets/references-hero.jpg'), lang: PL },
        // },
        // {
        //   path: 'references',
        //   name: 'References-en',
        //   component: () => import('@/views/references/Index.vue'),
        //   meta: { src: require('@/assets/references-hero.jpg'), lang: EN },
        // },
        {
          path: 'kontakt',
          name: 'Contact-pl',
          component: () => import('@/views/contact/Index.vue'),
          meta: { src: require('@/assets/about.jpg'), lang: PL, title: 'Kontakt' },
        },
        {
          path: 'contact',
          name: 'Contact-en',
          component: () => import('@/views/contact/Index.vue'),
          meta: { src: require('@/assets/about.jpg'), lang: EN, title: 'Contact' },
        },
        {
          path: 'rodo',
          name: 'Gdpr-pl',
          component: () => import('@/views/gdpr/Index.vue'),
          meta: { src: require('@/assets/gdpr.jpg'), lang: PL, upper: true, title: 'RODO' },
        },
        {
          path: 'gdpr',
          name: 'Gdpr-en',
          component: () => import('@/views/gdpr/Index.vue'),
          meta: { src: require('@/assets/gdpr.jpg'), lang: EN, upper: true, title: 'GDPR' },
        },
        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue'),
          meta: { lang: PL, title: '404' },
        },
      ],
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
   if (to.meta.lang !== store.state.language) {
      store.commit(SET_LANG, to.meta.lang)
     if (store.state[STORE_NAMESPACE_ALL]) {
       store.dispatch(`${STORE_NAMESPACE_ALL}/${GET_PAGE}`, ALL)
     }
    }
    next()
})

export default router
